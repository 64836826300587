<template>

    <div>

        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Pengajuan Progress
                        </h5>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body">
                            <input v-model="form.id_tahapan" type="hidden">
                            <input v-model="form.id_kemajuan" type="hidden">
                            <div class="form-group">
                                <label>Tanggal<span class="text-danger">*</span></label>
                                <datetime-picker  v-model="form.tanggal" :config="{timeZone: 'Asia/Jakarta'}"></datetime-picker>
                            </div>
                            <div class="form-group">
                                <label>Surat Permohonan<span class="text-danger">*</span></label>
                                <input ref="fileInput" @change="uploadFile" type="file" accept="application/pdf, image/x-png,image/gif,image/jpeg" value="" class="form-control" required="" placeholder="">
                            </div>
                            <hr>
                            <div class="alert alert-info">
                                <br>
                                <b>Keterangan</b><br>
                                - File yang didukung: <b>pdf, png, jpg, jpeg </b><br>
                                - Maksimal file : <b>5 Mb</b>
                                <br>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal</button>
                                <button v-if="loadAjukan == 0" type="submit" class="btn btn-primary">Ajukan</button>
                                <button v-if="loadAjukan == 1" type="button" disabled class="btn btn-primary">Loading...</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>

         <modal name="my-logbook" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Upload Logbook
                        </h5>
                    </div>
                    <form @submit.prevent="submitLogbook" enctype="multipart/form-data">
                        <div class="card-body">
                            <input v-model="form.id" type="hidden">
                            <input v-model="form.id_tahapan" type="hidden">
                            <input v-model="form.id_kemajuan" type="hidden">
                            <div class="form-group">
                                <label>Logbook<span class="text-danger">*</span></label>
                                <input ref="fileLogbook" @change="uploadLogbook" type="file" accept="application/pdf, image/x-png,image/gif,image/jpeg" value="" class="form-control" required="" placeholder="">
                            </div>
                            <div v-if="file_logbook != ''" class="mt-1"><i class="fa fa-file-o mr-2"></i><a :href="$apiconfig + 'uploads/logbook/' + file_logbook" target="_blank" class="text-primary">Lihat Logbook<i class="fa fa-external-link ml-2"></i></a></div>
                            <hr>
                            <div class="alert alert-info">
                                <br>
                                <b>Keterangan</b><br>
                                - File yang didukung: <b>pdf, png, jpg, jpeg </b><br>
                                - Maksimal file : <b>5 Mb</b>
                                <br>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_logbook()">Batal</button>
                                <button v-if="loadLogbook == 0" type="submit" class="btn btn-primary">Simpan</button>
                                <button v-if="loadLogbook == 1" type="button" disabled class="btn btn-primary">Loading...</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>

        <div class="container-fluid header-fixed">
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="section pt-md-5 pt-3">
                        <div class="d-block border-bottom">
                            <h3 class="text-dark">Progress Kemajuan</h3>
                            {{ print.judul }}
                        </div>

                        <!-- Formulir List Front -->
                        <div class="section pb-3 mt-3">

                            <div>
                                <!-- Show Data -->
                                <div v-if="listData.length > 0" class="row row-sm">
                                    <div v-for="(item, index) in listData" :key="index" class="col-md-3 mb-md-4 mb-3">
                                        <div class="card h-100 m-0">
                                            <div class="d-block h-100">
                                                <div class="d-flex flex-column h-100">
                                                    <div class="mb-0 flex-grow-1">
                                                        <img :src="'https://scola-uploader.sgp1.digitaloceanspaces.com/upload/14913c9dd9ccb03e585ebf97/mqdefault.jpg'" :style="'width:10'" class="card-img-top img-fluid wd-100p" alt="">
                                                        <div class="d-block p-3 mt-2">
                                                            <div class="badge badge-info">Tahap {{ index+1 }}</div>
                                                            <h5 class="text-dark mb-2 mt-1">{{ item.name }}</h5>
                                                            <div class="d-block px-3 py-2 bg-light rounded text-sm mb-2" style="border:1px red solid">
                                                                Maksimal : {{ item.next }}
                                                            </div>
                                                            <div v-if="item.pernyataan == 1" class="lh-sm lh-sm text-sm"><i class="fa fa-calendar mr-2"></i>{{ item.tanggal != '-' ? convertDate(item.tanggal) : '-' }}</div>
                                                            <div v-if="item.pernyataan == 0" class="lh-sm lh-sm text-sm"><i class="fa fa-calendar mr-2"></i>{{ item.tanggal != '-' ? onlyDate(item.tanggal) : '-' }}</div>
                                                            <div v-if="item.permohonan != null && item.permohonan != '-'" class="mt-1"><i class="fa fa-file-o mr-2"></i><a :href="$apiconfig + 'uploads/permohonan/' + item.permohonan" target="_blank" class="text-primary">Surat Permohonan<i class="fa fa-external-link ml-2"></i></a></div>
                                                            <div v-if="item.permohonan == null" class="mt-1"><i class="fa fa-file-o mr-2"></i><a class="text-muted">Tidak Ada Surat Permohonan</a></div>
                                                            <div v-if="item.permohonan == '-'" class="mt-1"><i class="fa fa-file-o mr-2"></i><a class="text-muted">-</a></div>
                                                            
                                                            <!-- <div v-if="item.undangan != null && item.undangan != '-'" class="mt-1"><i class="fa fa-file-o mr-2"></i><a style="cursor:pointer"  @mouseover="updateUndangan(item.idDetail)" @click="generateUndangan" class="text-primary">Surat Undangan<i class="fa fa-external-link ml-2"></i></a></div> -->
                                                            <div v-if="item.undangan != null && item.undangan != '-'" class="mt-1"><i class="fa fa-file-o mr-2"></i><a :href="$apiconfig + 'uploads/undangan/' + item.undangan" target="_blank" class="text-primary">Surat Undangan<i class="fa fa-external-link ml-2"></i></a></div>
                                                            <div v-if="item.undangan == null" class="mt-1"><i class="fa fa-file-o mr-2"></i><a class="text-muted">Tidak Ada Surat Undangan</a></div>
                                                            <div v-if="item.undangan == '-'" class="mt-1"><i class="fa fa-file-o mr-2"></i><a class="text-muted">-</a></div>

                                                            <!--
                                                            <div v-if="item.undangan != null && item.undangan != '-'" class="mt-1"><i class="fa fa-file-o mr-2"></i><a :href="$apiconfig + 'uploads/undangan/' + item.undangan" target="_blank" class="text-primary">Surat Pernyataan<i class="fa fa-external-link ml-2"></i></a></div>
                                                            <div v-if="item.pernyataan == 1" class="mt-1" ><i class="fa fa-file-o mr-2"></i><a style="cursor:pointer" @mouseover="updateSurat(item.idDetail)" @click="generateReport" class="text-primary">Surat Pernyataan<i class="fa fa-external-link ml-2"></i></a></div>
                                                            -->
                                                            <div v-if="item.pernyataan == null" class="mt-1"><i class="fa fa-file-o mr-2"></i><a class="text-muted">Tidak Ada Surat Pernyataan</a></div>
                                                            <div v-if="item.pernyataan == '-'" class="mt-1"><i class="fa fa-file-o mr-2"></i><a class="text-muted">-</a></div>
                                                            
                                                            <div v-if="item.berita_acara != null && item.berita_acara != '-'" class="mt-1"><i class="fa fa-file-o mr-2"></i><a :href="$apiconfig + 'uploads/berita_acara/' + item.berita_acara" target="_blank" class="text-primary">Berita Acara<i class="fa fa-external-link ml-2"></i></a></div>
                                                            <div v-if="item.berita_acara == null" class="mt-1"><i class="fa fa-file-o mr-2"></i><a class="text-muted">Tidak Ada Berita Acara</a></div>
                                                            <div v-if="item.berita_acara == '-'" class="mt-1"><i class="fa fa-file-o mr-2"></i><a class="text-muted">-</a></div>

                                                            <div v-if="item.sertifikat != null && item.sertifikat != '-' && item.name != 'Sidang Doktor'" class="mt-1"><i class="fa fa-file-o mr-2"></i><a :href="$apiconfig + 'uploads/sertifikat/' + item.sertifikat" target="_blank" class="text-primary">Surat Pernyataan<i class="fa fa-external-link ml-2"></i></a></div>
                                                            <div v-if="item.sertifikat == null && item.name != 'Sidang Doktor'" class="mt-1"><i class="fa fa-file-o mr-2"></i><a class="text-muted">Tidak Ada Surat Pernyataan</a></div>
                                                            <div v-if="item.sertifikat == '-' && item.name != 'Sidang Doktor'" class="mt-1"><i class="fa fa-file-o mr-2"></i><a class="text-muted">-</a></div>
                                                        </div>
                                                    </div>
                                                    <div class="d-block px-3 pb-3 pt-0">
                                                        <div v-if="item.next_tahapan == item.id || item.status != ''" class="row">
                                                            <div class="pl-3 pr-1" style="width:35%">
                                                                <button v-if="item.logbook == ''" class="btn btn-secondary btn-sm btn-block" @click="updateLogbook(item.id, item.idKemajuan, item.idLogbook, item.logbook)"><i class="fa fa-upload mr-2"></i>Logbook</button>
                                                                <button v-if="item.logbook != ''" class="btn btn-info btn-sm btn-block" @click="updateLogbook(item.id, item.idKemajuan, item.idLogbook, item.logbook)"><i class="fa fa-upload mr-2"></i>Logbook</button>
                                                            </div>
                                                            <div class="pr-3" style="width:65%">
                                                                <button v-if="item.status == 2" disabled class="btn btn-success btn-sm btn-block">Lulus</button>
                                                                <button v-if="item.status === 0" disabled class="btn btn-warning btn-sm btn-block">Pengajuan</button>
                                                                <button v-if="item.status === 4" disabled class="btn btn-info btn-sm btn-block">Disetujui</button>
                                                                <button v-if="item.status === ''  && item.next_tahapan == item.id && item.logbook != ''" @click="updateProgress(item.id, item.idKemajuan)" class="btn btn-outline-success btn-sm btn-block">Daftar</button>
                                                                <button v-if="item.status === ''  && item.next_tahapan == item.id && item.logbook == ''" @click="alertLogbook()" class="btn btn-outline-success btn-sm btn-block">Daftar</button>
                                                            </div>
                                                        </div>
                                                        <button v-if="item.status == ''  && item.next_tahapan != item.id " disabled class="btn alert-danger btn-block"><i class="fa fa-warning mr-2"></i>Selesaikan tahap sebelumnya!</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TRACER STUDY -->
                                    <div class="col-md-3 mb-md-4 mb-3">
                                        <div class="card h-100 m-0">
                                            <div class="d-block h-100">
                                                <div class="d-flex flex-column h-100">
                                                    <div class="mb-0 flex-grow-1">
                                                        <img :src="'https://scola-uploader.sgp1.digitaloceanspaces.com/upload/14913c9dd9ccb03e585ebf97/mqdefault.jpg'" :style="'width:10'" class="card-img-top img-fluid wd-100p" alt="">
                                                        <div class="d-block p-3 mt-2">
                                                            <h6 class="text-center text-dark mb-2 mt-1">Pendataan</h6>
                                                            <h5 class="text-center text-dark mb-2 mt-1">TRACER STUDY</h5>
                                                            <div class="text-center d-block px-3 py-2 bg-light rounded text-sm mb-2" style="border:1px red solid">
                                                                Mohon agar di isi dengan data diri Bapak/Ibu sebagai responsen survey
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="d-block px-3 pb-3 pt-0">
                                                        <!-- Dots Status -->
                                                        <router-link v-if="profil == 0" to="/mahasiswa/tracer-magister" class="col-12 btn btn-outline-danger">
                                                            ISI FORMULIR
                                                        </router-link>
                                                        <router-link v-if="profil == 1" to="/mahasiswa/tracer-magister" class="col-12 btn btn-outline-warning">
                                                            PERBAHARUI FORMULIR
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TRACER STUDY -->
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import { mapState } from 'vuex';
    import moment from 'moment';
    import DatetimePicker from 'vuejs-datetimepicker';

    export default {
        name: 'Beranda',
        components: {
            DatetimePicker,
        },
        metaInfo: {
            title: 'FTSL',
            titleTemplate: '%s - Progress Kemajuan'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                search: "",
                listData: [],
                profil: {},
                fetching: true,
                loadAjukan: 0,
                loadLogbook: 0,
                file_logbook: '',
                form: {
                    id : '',
                    tanggal : '',
                    berita_acara : '',
                    logbook_file : '',
                    id_tahapan: '',
                    id_kemajuan: '',
                },
                print: {
                    judul: '',
                    pembimbing: '',
                    studi_berjalan: '',
                    tahapan: '',
                    batas: '',
                    id_tahapan: '',
                    tanggal: '',
                },
                undangan: {
                    name: '',
                    nim: '',
                    judul: '',
                    tahapan: '',
                    pengutamaan: '',
                    id_tahapan: '',
                    tgl_undangan: '',
                    tanggal: '',
                    undangan: '',
                    pembimbing: ''
                }
            }
        },
        mounted() {
            this.$store.dispatch('loadUserData')
        },
        computed: mapState([
            'userdata'
        ]),
        created() {
            this.getData();
            this.getTracer();
        },
        methods: {
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            hide_logbook() {
                this.$modal.hide('my-logbook')
            },
            getTracer: function() {
                this.listData = [];
                this.fetching = true;
                let uri = this.$apiconfig + 'mahasiswa/profil/tracer-magister';
                this.$http.get(uri, {
                    params: {
                        keyword     : '',
                    }
                }).then(res => {					
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.profil = getResponse.data;
                    }
                    this.fetching = false;
                });
            },
            getData: function() {
                this.listData = [];
                this.fetching = true;
                let uri = this.$apiconfig + 'mahasiswa/kemajuan-magister';
                this.$http.get(uri, {
                    params: {
                        keyword     : '',
                    }
                }).then(res => {					
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.listData = getResponse.data;
                    } else {
                        this.listData = [];
                    }
                    this.fetching = false;
                });
            },
            uploadFile() {
                const file = this.$refs.fileInput.files[0]; 
                var fsize  = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                   this.form.berita_acara = this.$refs.fileInput.files[0];
                }
            },
            uploadLogbook() {
                const file = this.$refs.fileLogbook.files[0]; 
                var fsize  = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                   this.form.logbook_file = this.$refs.fileLogbook.files[0];
                }
            },
            updateProgress(idTahapan, idKemajuan) {
                this.form.id_tahapan = idTahapan;
                this.form.id_kemajuan = idKemajuan;
                this.$modal.show('my-modal');
            },
            updateLogbook(idTahapan, idKemajuan, idLogbook, logbook) {
                this.form.id = idLogbook;
                this.form.id_tahapan = idTahapan;
                this.form.id_kemajuan = idKemajuan;
                this.file_logbook = logbook;
                this.$modal.show('my-logbook');
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD hh:mm').lang("id").format('DD MMMM YYYY hh:mm');
            },
            convertTime : function (date) {
                return moment(date, 'YYYY-MM-DD hh:mm').lang("id").format('hh:mm');
            },
            convertDay : function (date) {
                return moment(date, 'YYYY-MM-DD hh:mm').lang("id").format('dddd, DD MMMM YYYY');
            },
            onlyDate : function (date) {
                return moment(date, 'YYYY-MM-DD').lang("id").format('DD MMMM YYYY');
            },
            alertLogbook() {
                this.$swal({
                    icon: 'error',
                    title: "PERINGATAN",
                    text: 'Upload Logbook terlebih dahulu sebelum mendaftar!',
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            submitForm() {
                this.loadAjukan = 1;
                const formData = new FormData();
                formData.append('file', this.form.berita_acara);
                formData.append('tanggal', this.form.tanggal);
                formData.append('id_tahapan', this.form.id_tahapan);
                formData.append('id_kemajuan', this.form.id_kemajuan);

                this.$http.post(this.baseUrl + 'mahasiswa/kemajuan/save-magister', formData)
                    .then((response) => {
                        if(response.data.status){
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }else{
                            this.$swal({
                                icon: 'error',
                                title: "Error",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                        
                        this.$modal.hide('my-modal')
                        this.loadAjukan = 0;
                        this.getData()
                        this.form.id = ''
                        this.form.tanggal = ''
                        this.form.berita_acara = ''
                        this.form.id_tahapan = ''
                        this.form.id_pengajuan = ''
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },
            submitLogbook() {
                this.loadLogbook = 1;
                const formData = new FormData();
                formData.append('id', this.form.id);
                formData.append('file', this.form.logbook_file);
                formData.append('id_tahapan', this.form.id_tahapan);
                formData.append('id_kemajuan', this.form.id_kemajuan);

                this.$http.post(this.baseUrl + 'mahasiswa/kemajuan/save_logbook_magister', formData)
                    .then((response) => {
                        if(response.data.status){
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }else{
                            this.$swal({
                                icon: 'error',
                                title: "Error",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                        
                        this.$modal.hide('my-logbook')
                        this.loadLogbook = 0;
                        this.getData()
                        this.form.id = ''
                        this.form.logbook_file = ''
                        this.form.id_tahapan = ''
                        this.form.id_pengajuan = ''
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },
            uploadFoto() {
                const file = this.$refs.fileInput.files[0]; 
                var fsize  = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 1) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                   this.form.berita_acara = this.$refs.fileInput.files[0];
                }
            },
            generateReport () {
                this.$refs.html2Pdf.generatePdf()
            },
            generateUndangan () {
                this.$refs.html2PdfUndangan.generatePdf()
            },
            updateSurat (id) {
                let uri = this.$apiconfig + 'mahasiswa/kemajuan/print-magister';
                this.$http.get(uri, {
                    params: {
                        id     : id,
                    }
                }).then(res => {					
                    let getResponse = res.data;
                    this.print.judul = getResponse.data.judul;
                    this.print.pembimbing = getResponse.data.pembimbing[0] ? getResponse.data.pembimbing[0] : '';
                    this.print.tahapan = getResponse.data.name;
                    this.print.batas = getResponse.data.batas_studi;
                    this.print.studi_berjalan = getResponse.data.progress_studi;
                    this.print.id_tahapan = getResponse.data.id_tahapan;
                    this.print.tanggal = getResponse.data.created_at;
                });
            },
            updateUndangan (id) {
                let uri = this.$apiconfig + 'mahasiswa/kemajuan/undangan-magister';
                this.$http.get(uri, {
                    params: {
                        id     : id,
                    }
                }).then(res => {					
                    let getResponse = res.data;
                    this.undangan.name = getResponse.data.fullname;
                    this.undangan.nim = getResponse.data.username;
                    this.undangan.undangan = getResponse.data.undangan;
                    this.undangan.judul = getResponse.data.judul;
                    this.undangan.pembimbing = getResponse.data.pembimbing;
                    this.undangan.tahapan = getResponse.data.name;
                    this.undangan.pengutamaan = getResponse.data.pengutamaan;
                    this.undangan.id_tahapan = getResponse.data.id_tahapan;
                    this.undangan.tgl_undangan = getResponse.data.tgl_undangan;
                    this.undangan.tanggal = getResponse.data.tanggal;
                });
            },
        }
    }
</script>